body{
	overscroll-behavior: contain
}

@font-face {
	font-family: Lato;
	src: url(./Lato-Regular.ttf);
}

#virtualplayer {
	font-family:  sans-serif;
	margin: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background-color: #000;
}

a:link,
a:visited {
	color: #bdc3c7;
}

.credit {
	position: absolute;
	text-align: center;
	width: 100%;
	padding: 20px 0;
	color: #fff;
}

#typed {
	color: #fff;
	text-shadow: 0px 0px 2px #000;
	font-size: 28pt;
	font-family: 'BikoBold';
}

#dialog {
	position: absolute;
	top: 50%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;
	box-sizing: border-box;
	font-family: 'BikoBold';
	-webkit-transition: opacity 0.5s ease;
	transition: opacity 0.5s ease;
}

#dialog.finish {
	opacity: 0;
	pointer-events: none;
}

#question {
	justify-content: center;
	align-items: center;
	display: flex;
	box-sizing: border-box;
	-webkit-transition: opacity 0.5s ease;
	transition: opacity 0.5s ease;
}

#question.finish {
	opacity: 0;
	pointer-events: none;
}

#progress {
	width: 0;
	height: 5px;
	position: fixed;
	top: 0;
	background: #fff;
	-webkit-transition: opacity 0.5s ease;
	transition: opacity 0.5s ease;
}

#progress.finish {
	opacity: 0;
}

.blur {
	/* The image used */
	background: #fff;
  
	/* Add the blur effect 
	filter: blur(8px);
	-webkit-filter: blur(8px);
	*/
  }

.scrollbar {
overflow: scroll;
}

.scrollbar::-webkit-scrollbar {
width: 8px;
background-color: #666666aa;
}

.scrollbar::-webkit-scrollbar-thumb {
border-radius: 4px;
background: #aaaaaa;
}
