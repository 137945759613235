body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'BikoBold';  
  src: local('BikoBold'), url(./fonts/Biko_Bold.otf) format('opentype');
}

@font-face {
  font-family: 'BikoBlack';  
  src: local('BikoBlack'), url(./fonts/Biko_Black.otf) format('opentype');
}

@font-face {
  font-family: 'BikoRegular';  
  src: local('BikoRegular'), url(./fonts/Biko_Regular.otf) format('opentype');
}

.btn:focus {
  outline: none !important;
  outline-offset: none !important;
}

.btn {
  outline: none !important;
  outline-offset: none !important;
}